<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3 class="text-medium my-4">Hatırlatma Ekle</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-12">
        <div class="card dveb-white-card setting-card">
          <div class="form-group">
            <label for="subject">Konu Seçin</label>
            <select name="" id="subject" class="form-control">
              <option value="" selected>Kira Gecikmesi</option>
              <option value="">Tesisat</option>
            </select>
          </div>
          <div class="form-group buttons">
            <label for="">Atama Yap</label>
            <b-button variant="outline-primary" @click="openAssigment()"
              >Mülk Sahibi Ata</b-button
            >
            <b-button variant="outline-primary" @click="openAssigment()"
              >Kiracı Ata</b-button
            >
            <b-button variant="outline-primary" @click="openAssigment()"
              >Teknik Destek Ata Ata</b-button
            >
          </div>
          <div class="form-group">
            <label for="status">İğnele</label>
            <b-dropdown
              class="status-dropdown"
              toggle-class="text-decoration-none"
              variant="outline-light"
              id="dropdown-1"
              style="width: 100%"
              no-caret
            >
              <template #button-content>
                <div class="status-button-text-template">
                  <span class="color-box"></span>
                  <p>Önemli</p>
                </div>
              </template>
              <b-dropdown-item class="button-list">
                <span class="color-box"></span>
                <p>Önemli</p>
              </b-dropdown-item>
              <b-dropdown-item
                ><span class="color-box"></span>
                <p>Önemli</p>
              </b-dropdown-item>
              <b-dropdown-item
                ><span class="color-box"></span>
                <p>Önemli</p>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-md-6 col-12">
        <div class="card dveb-white-card text-card">
          <input
            type="text"
            class="form-control title-input"
            placeholder="Buraya Başlık Yazılacak"
          />
          <span class="last-edit">En son 3 gün önce düzenlendi</span>
          <VueEditor v-model="content" class="text-editor" />
          <div class="not-icons">
            <button class="btn"><i class="flaticon2-calendar"></i></button>
            <button class="btn"><i class="flaticon2-bell-1"></i></button>
          </div>
        </div>
      </div>
    </div>
    <AssignmentModal
      title="Mülk Sahipleri"
      :show="showModal"
      @closeAssigment="closeAssigment"
    >
      <div class="assignment-modal-body">
        <input type="text" class="form-control search-input" />
        <div>
          <h4 class="modal-title">Mülk Sahipleri</h4>
        </div>
        <ul class="user-list">
          <li>
            <div class="user-info">
              <img src="/image/image-150.png" alt="" />
              <p>12345</p>
              <p>Bünyamin Ceyhun</p>
            </div>
            <div class="user-check">
              <b-form-checkbox
                id="checkbox-1"
                v-model="status"
                name="checkbox-1"
                value="accepted"
                size="lg"
              >
                Kiracıyı Seç
              </b-form-checkbox>
            </div>
          </li>
          <li>
            <div class="user-info">
              <img src="/image/image-150.png" alt="" />
              <p>12345</p>
              <p>Bünyamin Ceyhun</p>
            </div>
            <div class="user-check">
              <b-form-checkbox
                id="checkbox-1"
                v-model="status"
                name="checkbox-1"
                value="accepted"
                size="lg"
              >
                Kiracıyı Seç
              </b-form-checkbox>
            </div>
          </li>
          <li>
            <div class="user-info">
              <img src="/image/image-150.png" alt="" />
              <p>12345</p>
              <p>Bünyamin Ceyhun</p>
            </div>
            <div class="user-check">
              <b-form-checkbox
                id="checkbox-1"
                v-model="status"
                name="checkbox-1"
                value="accepted"
                size="lg"
              >
                Kiracıyı Seç
              </b-form-checkbox>
            </div>
          </li>
          <li>
            <div class="user-info">
              <img src="/image/image-150.png" alt="" />
              <p>12345</p>
              <p>Bünyamin Ceyhun</p>
            </div>
            <div class="user-check">
              <b-form-checkbox
                id="checkbox-1"
                v-model="status"
                name="checkbox-1"
                value="accepted"
                size="lg"
              >
                Kiracıyı Seç
              </b-form-checkbox>
            </div>
          </li>
          <li>
            <div class="user-info">
              <img src="/image/image-150.png" alt="" />
              <p>12345</p>
              <p>Bünyamin Ceyhun</p>
            </div>
            <div class="user-check">
              <b-form-checkbox
                id="checkbox-1"
                v-model="status"
                name="checkbox-1"
                value="accepted"
                size="lg"
              >
                Kiracıyı Seç
              </b-form-checkbox>
            </div>
          </li>
          <li>
            <div class="user-info">
              <img src="/image/image-150.png" alt="" />
              <p>12345</p>
              <p>Bünyamin Ceyhun</p>
            </div>
            <div class="user-check">
              <b-form-checkbox
                id="checkbox-1"
                v-model="status"
                name="checkbox-1"
                value="accepted"
                size="lg"
              >
                Kiracıyı Seç
              </b-form-checkbox>
            </div>
          </li>
          <li>
            <div class="user-info">
              <img src="/image/image-150.png" alt="" />
              <p>12345</p>
              <p>Bünyamin Ceyhun</p>
            </div>
            <div class="user-check">
              <b-form-checkbox
                id="checkbox-1"
                v-model="status"
                name="checkbox-1"
                value="accepted"
                size="lg"
              >
                Kiracıyı Seç
              </b-form-checkbox>
            </div>
          </li>
          <li>
            <div class="user-info">
              <img src="/image/image-150.png" alt="" />
              <p>12345</p>
              <p>Bünyamin Ceyhun</p>
            </div>
            <div class="user-check">
              <b-form-checkbox
                id="checkbox-1"
                v-model="status"
                name="checkbox-1"
                value="accepted"
                size="lg"
              >
                Kiracıyı Seç
              </b-form-checkbox>
            </div>
          </li>
        </ul>
      </div>
    </AssignmentModal>
  </div>
</template>
<script>
import { VueEditor } from 'vue2-editor';

export default {
  name: 'add-reminder',
  components: {
    VueEditor,
    AssignmentModal: () => import('@/components/global/AssignmentModal'),
  },
  data() {
    return {
      content: '',
      showModal: false,
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'code-block'],
      ],
    };
  },
  methods: {
    openAssigment() {
      this.showModal = true;
    },
    closeAssigment() {
      this.showModal = false;
    },
  },
};
</script>
<style lang="scss">
.setting-card {
  .form-control:focus {
    --bs-form-select-bg-img: url("data:image/svg+xml,%0A%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='ic_down arrow'%3E%3Cpath id='Rectangle' fill-rule='evenodd' clip-rule='evenodd' d='M10.2929 0.333008C10.7122 0.333008 10.9453 0.817974 10.6834 1.14536L6.39035 6.51163C6.19019 6.76183 5.80965 6.76183 5.60948 6.51163L1.31646 1.14535C1.05456 0.817973 1.28764 0.333008 1.7069 0.333008L10.2929 0.333008Z' fill='%23102640'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-color: #fff;
    background-image: var(--bs-form-select-bg-img),
      var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
  }

  label {
    color: $dveb-primary;
    font-size: 1.23077rem;
    font-weight: 600;
    letter-spacing: 0.00769rem;
  }

  select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%0A%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='ic_down arrow'%3E%3Cpath id='Rectangle' fill-rule='evenodd' clip-rule='evenodd' d='M10.2929 0.333008C10.7122 0.333008 10.9453 0.817974 10.6834 1.14536L6.39035 6.51163C6.19019 6.76183 5.80965 6.76183 5.60948 6.51163L1.31646 1.14535C1.05456 0.817973 1.28764 0.333008 1.7069 0.333008L10.2929 0.333008Z' fill='%23102640'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-color: #fff;
    background-image: var(--bs-form-select-bg-img),
      var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border-radius: 0.76923rem;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & {
      flex: 1;
    }
  }

  .show > .btn.btn-outline-light.dropdown-toggle {
    background-color: #fff;
  }

  .status-dropdown {
    button {
      &:hover {
        background-color: #fff !important;
      }

      border-radius: 0.76923rem !important;
      display: inline-flex;
      align-items: center;
      gap: 0.77rem;
      font-size: 1.07692rem;
      font-weight: 400;
      --bs-form-select-bg-img: url("data:image/svg+xml,%0A%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='ic_down arrow'%3E%3Cpath id='Rectangle' fill-rule='evenodd' clip-rule='evenodd' d='M10.2929 0.333008C10.7122 0.333008 10.9453 0.817974 10.6834 1.14536L6.39035 6.51163C6.19019 6.76183 5.80965 6.76183 5.60948 6.51163L1.31646 1.14535C1.05456 0.817973 1.28764 0.333008 1.7069 0.333008L10.2929 0.333008Z' fill='%23102640'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-color: #fff;
      background-image: var(--bs-form-select-bg-img),
        var(--bs-form-select-bg-icon, none);
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 16px 12px;
    }

    ul,
    li {
      width: 100%;

      a {
        display: flex;
        align-items: center;
        gap: 0.77rem;
      }
    }

    .status-button-text-template {
      display: flex;
      align-items: center;
      gap: 0.77rem;
    }
  }

  .color-box {
    display: inline-block;
    width: 1.84615rem;
    height: 1.84615rem;
    background-color: rgba(252, 90, 90, 1);
    border-radius: 50%;
  }
}

.text-card {
  .ql-stroke,
  .ql-fill,
  .ql-picker-label {
    stroke: $dveb-primary !important;
    color: $dveb-primary !important;
  }

  .title-input {
    border: none;
    font-size: 1.38462rem;
    font-weight: 600;
    color: $dveb-primary;

    &::placeholder {
      color: rgba($dveb-primary, 0.5);
    }

    &:focus {
      background-color: #fff !important;
      border: none;
    }
  }

  .last-edit {
    padding: 0 1.1rem 1.65rem 1.1rem;
    color: $dveb-primary;
    font-size: 0.92308rem;
  }

  .text-editor {
    padding: 0 1rem 0.65rem 1rem;

    .ql-toolbar {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid rgba($dveb-primary, 0.5);
      border-top: 1px solid rgba($dveb-primary, 0.5);
    }

    .ql-container {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid rgba($dveb-primary, 0.5);
      min-height: 30rem;
    }
  }

  .not-icons {
    padding: 0 1rem 0.65rem 1rem;
    display: flex;
    align-items: center;

    i {
      font-size: 1.69231rem;
      color: $dveb-primary;
    }
  }
}

.assignment-modal-body {
  display: flex;
  flex-direction: column;

  .search-input {
    --bs-form-select-bg-img: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z' stroke='%23ADA7A7' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M22 22L20 20' stroke='%23ADA7A7' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-color: #fff;
    background-image: var(--bs-form-select-bg-img),
      var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: left 0.75rem center;
    background-size: 16px 12px;
    border-radius: 0.76923rem;
    padding: 1.08rem 1.08rem 1.08rem 2.5rem;
    margin-bottom: 2.5rem;
    width: 50%;
  }

  .modal-title {
    font-size: 1.53846rem;
    line-height: 1.69231rem;
    font-weight: 500;
    color: $dveb-info;
    padding-bottom: 0.33rem;
    border-bottom: 1px solid rgba($dveb-primary, 0.5);
    display: inline-block;
    margin-bottom: 3rem;
  }

  .user-list {
    list-style: none;
    padding-right: 8.5rem;
    max-height: 500px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: #dbdbdb;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #dbdbdb;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #dbdbdb;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $dveb-primary;

      .user-info {
        display: flex;
        align-items: center;
        gap: 1.31rem;
        padding-bottom: 1.38rem;

        img {
          width: 50px;
          border-radius: 999px;
        }

        p {
          font-size: 1.23077rem;
          font-weight: 500;
          line-height: 1.23077rem;
          color: $dveb-primary;
        }
      }

      .user-check {
        padding-right: 3rem;
        display: flex;
        align-items: center;
        height: 50px;

        .custom-control-label {
          font-size: 1.53846rem;
          line-height: 1.69231rem;
          color: $dveb-primary;

          &::before,
          &::after {
            width: 2.07692rem;
            height: 1.92308rem;
            left: -3.5rem;
            top: -0.2rem;
          }
        }
      }
    }
  }
}
</style>
